<template>
  <div class="outer-page">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      rowKey="id"
      :load-data="handleLoadData"
    ></x-table>
    <Modal
      v-model="modal.show"
      :width="500"
      :title="modal.title"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="120" :rules="rules">
        <FormItem label="地区名称" prop="name">
          <Input
            clearable
            v-model="form.name"
            placeholder="请填写地区名称"
            style="width: 280px"
          ></Input>
        </FormItem>
        <FormItem label="地区编码" prop="zipCode">
          <Input
            clearable
            v-model="form.zipCode"
            placeholder="请填写地区编码"
            style="width: 280px"
          ></Input>
        </FormItem>
        <FormItem label="城市编码" prop="cityCode">
          <Input
            clearable
            v-model="form.cityCode"
            placeholder="请填写城市编码"
            style="width: 280px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button @click="() => (this.modal.show = false)">取消</Button>
        <Button type="primary" @click="submit">提交</Button>
      </p>
    </Modal>
    <Modal
      v-model="sorts.show"
      title="排序"
      :width="450"
      @on-visible-change="modalChanges"
    >
      <Form :label-width="100">
        <FormItem label="排序">
          <Input v-model="sorts.val" style="width: 260px"></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button @click="() => (this.sorts.show = false)">取消</Button>
        <Button type="primary" @click="submitSort">提交</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "地区名称",
            key: "name",
            minWidth: 160,
            tree: true,
          },
          {
            title: "排序",
            key: "sort",
          },
          {
            title: "操作",
            width: 260,
            render: (h, { row }) => {
              return (
                <div>
                  <a style="margin-right:20px" on-click={() => this.edit(row)}>
                    编辑
                  </a>
                  <a style="margin-right:20px" on-click={() => this.add(row)}>
                    新增下级
                  </a>
                  <a style="margin-right:20px" on-click={() => this.sort(row)}>
                    排序
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.delete(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
        handloading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增地区",
            ca: "",
          },
          searchBtnHide: true,
        },
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        parentId: "",
        name: "",
        zipCode: "",
        cityCode: "",
      },
      rules: {
        name: [{ required: true, message: "请填写地区名称" }],
        zipCode: [{ required: true, message: "请填写地区编码" }],
        cityCode: [{ required: true, message: "请填写城市编码" }],
      },
      areaList: [],
      areaPid: "",
      sorts: {
        info: {},
        show: false,
        val: "",
      },
    };
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: 100000,
        ...this.search_data,
      })
        .then((res) => {
          //   this.table.data = res;
          res.forEach((item) => {
            item._loading = false;
            item.children = [];
          });
          this.table.data = res;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    handleLoadData(item, callback) {
      this.table.handloading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: Number(item.id),
      })
        .then((res) => {
          res.forEach((item) => {
            item._loading = false;
            item.children = [];
          });
          const data = res;
          callback(data);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    search(value) {
      this.search_data = value;
      this.getList();
    },
    add(row) {
      if (row) {
        this.form.parentId = row.id;
      } else {
        this.form.parentId = 100000;
      }
      this.modal = {
        show: true,
        title: "添加地区",
        submitLoading: false,
      };
    },
    edit(row) {
      this.form.name = row.name;
      this.form.zipCode = row.name;
      this.form.cityCode = row.name;
      this.form.id = row.id;
      this.modal = {
        show: true,
        title: "编辑地区",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.AREA_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.AREA_MANAGE.UPDATE;
        } else {
          url = this.$api.AREA_MANAGE.ADD;
        }
        if (!res) return;
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.$refs.form.resetFields();
          this.modal.show = false;
        });
      });
    },
    sort(row) {
      this.sorts = {
        show: true,
        info: row,
      };
    },
    submitSort() {
      let params = { ...this.sorts.info, sort: this.sorts.val };
      this.$post(this.$api.AREA_MANAGE.UPDATE, params).then(() => {
        this.$Message.success("排序成功");
        this.getList();
        this.sorts.show = false;
      });
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        parentId: "",
        name: "",
        zipCode: "",
        cityCode: "",
      };
      this.$refs.form.resetFields();
    },
    modalChanges(visible) {
      if (visible) return;
      this.sorts = {
        info: {},
        val: "",
      };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>